<template>
  <el-form class="up-filter-form" :inline="true">
    <el-form-item label="УЦ:" v-loading="!centersIsLoaded">
      <el-select
        v-model="form.partnerId"
        filterable
        placeholder="УЦ:"
        @change="changePartnerHandler"
      >
        <el-option label="Все" value="all" />
        <el-option
          v-for="c in centers"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Клиент:" v-loading="!customersIsLoaded">
      <el-select
        v-model="form.customerId"
        filterable
        placeholder="Клиент:"
        @change="$emit('filter')"
      >
        <el-option label="Все" value="all" />
        <el-option
          v-for="c in customers"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Курс:" v-loading="!coursesLoaded">
      <el-select
        v-model="form.courseId"
        placeholder="Курс:"
        popper-class="up-choose-course-popover"
        @visible-change="openModalChooseCourse"
      >
        <el-option label="Все" value="all" />
        <template v-for="direction in coursesTree">
          <template v-for="category in direction.childs">
            <el-option
              v-for="course in category.courses"
              :key="course.id"
              :label="course.name"
              :value="course.id"
            />
          </template>
        </template>
      </el-select>
    </el-form-item>
    <AppModalChooseCourse
      :visible.sync="modalChooseCoursevisible"
      :courses-tree="coursesTree"
      has-choose-all-btn
      @choose-course="chooseCourseHandler"
    />
  </el-form>
</template>

<script>
import chooseCourseMixin from "@/mixins/chooseCourse.mixin";
import showErrorMessage from "@/mixins/showErrorMessage.mixin";

export default {
  nmae: "FilterForm",
  mixins: [chooseCourseMixin, showErrorMessage],
  props: {
    value: null,
  },
  data: () => ({
    centers: [],
    centersIsLoaded: false,
    customers: [],
    customersIsLoaded: false,
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async fetchPartners() {
      try {
        const { data } = await this.$axios.get("/admin/partners");

        this.centers = data;
        this.centersIsLoaded = true;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async fetchCustomers() {
      try {
        const { data } = await this.$axios.get(
          `/admin/partner/${this.form.partnerId}/customers`
        );

        this.customers = data;
        this.customersIsLoaded = true;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async changePartnerHandler() {
      await this.fetchCustomers();

      this.form.customerId = "all";
      this.$emit("filter");
    },
    chooseCourseHandler(courseId) {
      this.form.courseId = courseId;
      this.modalChooseCoursevisible = false;
      this.$emit("filter");
    },
  },
  created() {
    this.fetchPartners();
    this.fetchCustomers();
    this.fetchTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/requests/filter-form.scss";
</style>

<style lang="scss">
.up-choose-course-popover {
  display: none !important;
}
</style>