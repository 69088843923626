<template>
  <div class="up-requests-wrapper">
    <h1 class="up-main-title">Все заявки</h1>
    <FilterForm v-model="form" @filter="filterHandler" />
    <template v-if="!loading">
      <template v-if="requests.length > 0">
        <AppSort
          :sort-params="sortParams"
          :sort-by="sortBy"
          :sort-order="sortOrder"
          @sort="sortHandler($event, 'fetchRequests')"
        />
        <AppBlockAsTable class="up-manager__requests up-requests">
          <template slot="header">
            <p
              class="up-requests__number"
              :class="activeSortClass('number')"
              @click="sortHandler('number', 'fetchRequests')"
            >
              Номер <i class="el-icon-top" /> <i class="el-icon-bottom" />
            </p>
            <p
              class="up-requests__center"
              :class="activeSortClass('partner')"
              @click="sortHandler('partner', 'fetchRequests')"
            >
              УЦ <i class="el-icon-top" /> <i class="el-icon-bottom" />
            </p>
            <p
              class="up-requests__customer"
              :class="activeSortClass('customer')"
              @click="sortHandler('customer', 'fetchRequests')"
            >
              Клиент <i class="el-icon-top" /> <i class="el-icon-bottom" />
            </p>
            <p
              class="up-requests__course"
              :class="activeSortClass('course')"
              @click="sortHandler('course', 'fetchRequests')"
            >
              Курс <i class="el-icon-top" /> <i class="el-icon-bottom" />
            </p>
            <p class="up-requests__listeners"><i class="el-icon-user" /></p>
            <p class="up-requests__status"><i class="el-icon-video-play" /></p>
            <p class="up-requests__date">Доступ до:</p>
          </template>
          <div v-for="r in requests" :key="r.id" class="up-table__item">
            <p class="up-requests__number">
              <span class="is-pk-hidden">Номер:</span>{{ r.number }}
            </p>
            <p class="up-requests__center">
              <span class="is-pk-hidden">Центр:</span>{{ r.partner.name }}
            </p>
            <p class="up-requests__customer">
              <span class="is-pk-hidden">Клиент:</span>{{ r.customer.name }}
            </p>
            <p class="up-requests__course">
              <span class="is-pk-hidden">Курс:</span>{{ r.course.name }}
            </p>
            <p class="up-requests__listeners">
              <span class="is-pk-hidden">Слушатели:</span>{{ r.listenerCount }}
            </p>
            <p class="up-requests__status">
              <span class="is-pk-hidden">Статус:</span>
              <el-tag type="success">
                {{ statusTranslated(r.orderStatus) }}
              </el-tag>
            </p>
            <p class="up-requests__date">
              <span class="is-pk-hidden">Доступ до:</span>
              <template v-if="r.dateUntil">
                {{ r.dateUntil | ruDate("d.m.Y") }}
              </template>
            </p>
          </div>
        </AppBlockAsTable>
        <el-pagination
          v-if="totalSize > perPage"
          background
          layout="prev, pager, next"
          :total="totalSize"
          :current-page.sync="currentPage"
          :page-size="perPage"
          @current-change="fetchRequests"
        />
      </template>
      <p v-else class="up-empty-list-text">Список пуст</p>
    </template>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSort from "@/components/AppSort";
import FilterForm from "@/components/admin/requests/FilterForm";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";
import sortMixin from "@/mixins/sort.mixin";

import requestStatuses from "@/enums/requestStatuses";

import { mapActions } from "vuex";

export default {
  name: "EducationalCenterRequests",
  mixins: [showErrorMessageMixin, sortMixin],
  components: { AppBlockAsTable, AppSort, FilterForm },
  data: () => ({
    form: {
      partnerId: "all",
      customerId: "all",
      courseId: "all",
    },
    loading: true,
    requests: [],
    perPage: 10,
    currentPage: 1,
    totalSize: 0,
    sortParams: [
      {
        text: "Номеру",
        key: "number",
      },
      {
        text: "Учебному центру",
        key: "partner",
      },
      {
        text: "Заказчику",
        key: "customer",
      },
      {
        text: "Курсу",
        key: "course",
      },
    ],
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchRequests() {
      this.$nprogress.start();

      try {
        const { data: requests } = await this.$axios.get(
          `/admin/orders/${this.form.partnerId}/${this.form.customerId}/${this.form.courseId}`,
          {
            params: {
              pageSize: this.perPage,
              pageNumber: this.currentPage - 1,
              sortBy: this.sortBy,
              sortOrder: this.sortOrder,
            },
          }
        );

        this.requests = requests.items || [];
        this.totalSize = requests.totalSize;
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    filterHandler() {
      this.currentPage = 1;
      this.fetchRequests();
    },
    statusTranslated(requestStatus) {
      const status = requestStatuses.find((s) => s.state === requestStatus);

      return status ? status.text : requestStatus;
    },
  },
  created() {
    this.setNavigationText("Страница всех заявок");

    this.fetchRequests();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/admin/requests.scss";
</style>